import { mapState } from 'vuex';
import { outRecordList, updateState } from '@/service/cloudWarehouse/index'

export default {
    name: 'outOrder',
    data(){
        return{
            order:[],
            offset: 1,
            loading: true,
            finished: false
        }
    },
    mounted(){
        this.getOutRecordList()
    },
    methods:{
        async getOutRecordList(){
            let { order, offset } = this   
            const params = {
                suborderCode: this.$route.query.code,
                limit: 10,
                offset: (offset - 1) * 10
            }

            const res = await outRecordList(params)
            this.loading = false

            this.order = [...order, ...res]
            if (res.length < 10) this.finished = true
        },
        // 点击按钮
        onBtn(row){
            let { order } = this
            if(row.state != 1) return
  
            this.$dialog.confirm({
                title: '提示',
                message: '确定收货？',
                async beforeClose(action, done) {
                    if(action === 'confirm'){
                        await updateState({ id: row.id, state: 4 })
                        order.forEach(item =>{
                            if(item.id == row.id) item.state = 4
                        })
                        this.order = order

                        done()
                        this.$toast('确认收货成功')
                    }else done()
                }
            })
        },
        // 加载更多
        onLoad(){
            this.offset ++
            this.getOutRecordList()
        }
    }
}